import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
import { Link } from 'react-router-dom';
import { X, Download } from 'lucide-react';
import { FaPlus } from 'react-icons/fa';
import { RiRobot2Line } from 'react-icons/ri';
import { MdOutlineDelete } from 'react-icons/md';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { FaRegFolderOpen } from 'react-icons/fa';
import { FaSignature } from 'react-icons/fa'; // Importe o ícone que deseja usar

import ConfirmationPopup from './Global/ConfirmationPopup';

import { REACT_APP_SERVER_URL } from '../config/keys';
import { getUserFromLocalStorage } from '../services';

interface UserInfo {
    userId: string;
    name: string;
    availableCredits: number;
}

interface DownloadData {
    nomeSobrenome: string;
    dataNascimento: string;
    filename: string;
    localNascimento: string;
    ufNascimento: string;
    dataEmissao: string;
    validadeCNH: string;
    numeroRegistro: string;
    categoria: string;
    nacionalidade: string;
    filiacaoPai: string;
    filiacaoMae: string;
    localidadeCNH: string;
    ufLocalidadeCNH: string;
    codigoVerificacaoCNH: string;
}

interface InvoiceResponse {
    _id: string;
    nomeSobrenome: string;
    cpf: string;
    filename: string;
    folder: string;
    dataNascimento: string;
    age: string;
    validadeCNH: string;
    isCNHValid: string; // Alterado para booleano
    categoria: string;
    filiacaoPai: string;
    filiacaoMae: string;
    croppedImageBuffer: string | null; // Adicionado campo para a imagem
}

interface UserInfo {
    userId: string;
    name: string;
    availableCredits: number;
}

const Cnh: React.FC = () => {
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
    const [companies, setCompanies] = useState<InvoiceResponse[]>([]);
    const [allCompanies, setAllCompanies] = useState<InvoiceResponse[]>([]);
    const [download, setDownload] = useState<DownloadData[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [kpisData, setKpisData] = useState<any>({});
    const [copiedText, setCopiedText] = useState('');
    const companiesPerPage = 3;
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [companyIdToDelete, setCompanyIdToDelete] = useState<string | null>(
        null
    );

    const [showConfirmationPopupAllDelete, setShowConfirmationPopupAllDelete] =
        useState(false);

    const [searchFilter, setSearchFilter] = useState<string>('');
    const [filteredCompanies, setFilteredCompanies] = useState<
        InvoiceResponse[]
    >([]);

    const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
    const [loading, setLoading] = useState(false);
    const [analysisCompleted, setAnalysisCompleted] = useState(false);
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [totalNumberOfPages, setTotalNumberOfPages] = useState(0);
    const [updatedUserCredits, setUpdatedUserCredits] = useState(0);
    const [results, setResults] = useState([]);
    const [executionTime, setExecutionTime] = useState(0);
    const [totalFiles, setTotalFiles] = useState(0);
    const [successfulFiles, setSuccessfulFiles] = useState(0);
    const [errorFilesNames, setErrorFilesNames] = useState([]);
    const [showWaitPopup, setShowWaitPopup] = useState(false); // Estado para controlar a exibição do popup de espera
    const [showWaitPopupDeleteAll, setShowWaitPopupDeleteAll] = useState(false); // Estado para controlar a exibição do popup de espera
    const [isRequestInProgress, setIsRequestInProgress] = useState(false);
    const [requestStartTime, setRequestStartTime] = useState(0);
    const [showInputPopup, setShowInputPopup] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [folders, setFolders] = useState<string[]>([]);
    const [selectedFolder, setSelectedFolder] = useState<string>('');
    const [isFolderSelected, setIsFolderSelected] = useState(false);
    const [totalNotas, setTotalNotas] = useState(0);
    const [notasDistintas, setNotasDistintas] = useState(0);
    const [servicosDistintos, setServicosDistintos] = useState(0);
    const [totalCNPJs, setTotalCNPJs] = useState(0);
    const [totalCidades, setTotalCidades] = useState(0);
    const [showDownloadPopup, setShowDownloadPopup] = useState(false);
    const [selectedDownloadFolder, setSelectedDownloadFolder] = useState('');
    const [selectedDate, setSelectedDate] = useState('');

    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [selectedDeleteFolder, setSelectedDeleteFolder] = useState('');
    const [deleteStatusMessage, setDeleteStatusMessage] = useState('');

    useEffect(() => {
        const filteredCompanies = allCompanies.filter(
            company =>
                selectedFolder === '' || company.folder === selectedFolder
        );

        // Atualiza a lista de empresas filtradas com base na pasta selecionada
        setFilteredCompanies(filteredCompanies);

        // Calcula o total de notas
        setTotalNotas(filteredCompanies.length);

        // Calcula o total de notas distintas
        const distinctNotesSet = new Set(
            filteredCompanies.map(company => company.cpf)
        );
        setNotasDistintas(distinctNotesSet.size);

        // Calcula o total de serviços distintos
        // Ajuste essa lógica caso 'servicoPrestado' não seja um campo diretamente disponível
        // e você tenha uma estrutura diferente para calcular serviços distintos
        const distinctServicesSet = new Set(
            filteredCompanies.flatMap(company => company.cpf || [])
        );
        setServicosDistintos(distinctServicesSet.size);

        // Calcula o total de CNPJs distintos
        const distinctCNPJsSet = new Set(
            filteredCompanies.map(company => company.cpf)
        );
        setTotalCNPJs(distinctCNPJsSet.size);

        // Calcula o total de cidades distintas
        const distinctCitiesSet = new Set(
            filteredCompanies.map(company => `${company.cpf} - ${company.cpf}`)
        );
        setTotalCidades(distinctCitiesSet.size);
    }, [selectedFolder, allCompanies]);

    useEffect(() => {
        // Filtra as empresas com base no texto de pesquisa
        const filterCompanies = () => {
            if (!searchFilter.trim()) {
                // Se o filtro de pesquisa estiver vazio, retorne todas as empresas
                return allCompanies;
            }

            // Filtra as empresas que correspondem ao filtro de pesquisa
            return allCompanies.filter(company => {
                // Coloque aqui a lógica de correspondência, dependendo dos atributos de uma empresa que você deseja pesquisar.
                // Exemplo: pesquisa pelo nome da empresa (razaoSocialPrestador) ou pelo número da nota (numeroDaNota).
                // Adapte os atributos conforme necessário para sua aplicação.
                return (
                    company.nomeSobrenome
                        .toLowerCase()
                        .includes(searchFilter.toLowerCase()) ||
                    company.cpf
                        .toLowerCase()
                        .includes(searchFilter.toLowerCase())
                );
            });
        };

        const filtered = filterCompanies();
        setFilteredCompanies(filtered);
    }, [searchFilter, allCompanies]); // Depende do searchFilter e allCompanies para reagir a mudanças

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');

        axios
            .get<InvoiceResponse[]>(
                `${REACT_APP_SERVER_URL}/api/cnh/cnh-data/${JSON.parse(
                    storedUserId!
                )}`
            )
            .then(response => {
                setAllCompanies(response.data);
                setCompanies(response.data);
            })
            .catch(error => {
                console.error('Error fetching user information:', error);
            });
    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');

        if (storedUserId) {
            axios
                .get(
                    `${REACT_APP_SERVER_URL}/api/cnh/kpis-invoice-data/${JSON.parse(
                        storedUserId
                    )}`
                )
                .then(response => {
                    setKpisData(response.data);
                })
                .catch(error => {
                    console.error('Error fetching KPIs data:', error);
                });
        }
    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
            axios
                .get(
                    `${REACT_APP_SERVER_URL}/api/cnh/folders/${JSON.parse(
                        storedUserId
                    )}`
                )
                .then(response => {
                    setFolders(response.data); // Supondo que a resposta seja diretamente o array
                })
                .catch(error => {
                    console.error('Error fetching folders:', error);
                });
        }
    }, []);

    const indexOfLastCompany = currentPage * companiesPerPage;
    const indexOfFirstCompany = indexOfLastCompany - companiesPerPage;
    const currentFilteredCompanies = filteredCompanies.slice(
        indexOfFirstCompany,
        indexOfLastCompany
    );

    const totalPages = Math.ceil(filteredCompanies.length / companiesPerPage);

    const handleNextPage = () => {
        setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
    };

    const handlePrevPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleSearchClick = () => {
        if (!selectedFiles || selectedFiles.length === 0) {
            console.error('Nenhum arquivo selecionado.');
            return;
        }
        setShowInputPopup(true);
    };

    const handleConfirm = async () => {
        if (!selectedFiles) {
            console.error('Nenhum arquivo selecionado.');
            setShowInputPopup(false);
            return;
        }

        setShowInputPopup(false);
        setShowWaitPopup(true);
        const start = new Date().getTime();

        const user = getUserFromLocalStorage();
        if (user && user.userId) {
            const userId = user.userId.replace(/^"(.*)"$/, '$1');
            try {
                setLoading(true);

                const formData = new FormData();
                for (let i = 0; i < selectedFiles.length; i++) {
                    formData.append('pdfFiles', selectedFiles[i]);
                }

                formData.append('inputValue', inputValue);

                const endpoint = `${REACT_APP_SERVER_URL}/api/cnh/create?author_id=${userId}`;

                const response = await axios.post(endpoint, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                const {
                    totalFiles,
                    successfulFiles,
                    errorFiles,
                    errorFilesNames,
                    numberOfPages,
                    totalNumberOfPages,
                    updatedUserCredits,
                    results,
                } = response.data;

                console.log('Número total de arquivos:', totalFiles);
                console.log(
                    'Número de arquivos processados com sucesso:',
                    successfulFiles
                );
                console.log('Número de arquivos que falharam:', errorFiles);
                console.log(
                    'Nomes dos arquivos que falharam:',
                    errorFilesNames
                );
                console.log('Número total de páginas:', numberOfPages);
                console.log(
                    'Número total de páginas em todos os arquivos:',
                    totalNumberOfPages
                );
                console.log(
                    'Créditos atualizados do usuário:',
                    updatedUserCredits
                );
                console.log('Resultados do processamento:', results);

                setNumberOfPages(numberOfPages);
                setTotalNumberOfPages(totalNumberOfPages);
                setUpdatedUserCredits(updatedUserCredits);
                setResults(results);
                setTotalFiles(totalFiles);
                setSuccessfulFiles(successfulFiles);
                setErrorFilesNames(errorFilesNames);

                setAnalysisCompleted(true);
            } catch (error) {
                console.error('Erro durante o envio de arquivos:', error);
            } finally {
                setLoading(false);
                setShowWaitPopup(false);

                const end = new Date().getTime();
                const elapsedTime = end - start;
                setExecutionTime(elapsedTime);
            }
        }
    };

    const handlePopupOkClick = () => {
        setAnalysisCompleted(false);
        window.location.reload();
    };

    const handleDownloadExcel = () => {
        setShowDownloadPopup(true);
    };

    const handleDownloadSelectedFolder = async () => {
        setShowDownloadPopup(false); // Fechar o popup

        // Aqui, você pega o ID do usuário ou qualquer outra informação necessária do localStorage
        const userId = JSON.parse(localStorage.getItem('userId') || '{}');
        const formattedDate = selectedDate.split('-').reverse().join('/');

        try {
            // Aqui você envia o request para o servidor para obter os dados da pasta selecionada
            // Supondo que a resposta seja o array de dados correspondente à pasta
            const { data } = await axios.post(
                `${REACT_APP_SERVER_URL}/api/cnh/download-cnh-data/${userId}`,
                {
                    folder: selectedDownloadFolder,
                    startDate: formattedDate,
                }
            );

            // Mapeamento dos dados para o formato compatível com XLSX
            const rows = data.map((company: DownloadData) => ({
                FILENAME: company.filename || 'N/A',
                'NOME COMPLETO': company.nomeSobrenome || 'N/A',
                'DATA NASCIMENTO': company.dataNascimento || 'N/A',
                'LOCAL NASCIMENTO': company.localNascimento || 'N/A',
                'UF NASCIMENTO': company.ufNascimento || 'N/A',
                'DATA EMISSAO': company.dataEmissao || 'N/A',
                'VALIDADE CNH': company.validadeCNH || 'N/A',
                'NUMERO REGISTRO': company.numeroRegistro || 'N/A',
                CATEGORIA: company.categoria || 'N/A',
                NACIONALIDADE: company.nacionalidade || 'N/A',
                'FILIACAO PAI': company.filiacaoPai || 'N/A',
                'FILIACAO MAE': company.filiacaoMae || 'N/A',
                'LOCALIDADE CNH': company.localidadeCNH || 'N/A',
                'UF LOCALIDADE CNH': company.ufLocalidadeCNH || 'N/A',
                'CODIGO VERIFICACAO CNH': company.codigoVerificacaoCNH || 'N/A',
            }));

            // Criação do workbook e da planilha
            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.json_to_sheet(rows);
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Notas Fiscais');
            XLSX.writeFile(
                workbook,
                `CNHs-${selectedDownloadFolder || 'Todas'}.xlsx`,
                { compression: true }
            );
        } catch (error) {
            console.error('Erro ao baixar dados da pasta:', error);
            // Aqui você pode definir como deseja tratar o erro, por exemplo, exibindo uma mensagem para o usuário
        }
    };

    const handleDeleteCompany = (companyId: string) => {
        setCompanyIdToDelete(companyId);
        setShowConfirmationPopup(true);
    };

    const handleCancelDelete = () => {
        setCompanyIdToDelete(null);
        setShowConfirmationPopup(false);
    };

    const handleDownloadImage = (
        croppedImageBuffer: string | null,
        filename: string
    ) => {
        if (!croppedImageBuffer) {
            console.error('Imagem não disponível');
            return;
        }

        const link = document.createElement('a');
        link.href = `data:image/png;base64,${croppedImageBuffer}`;
        link.download = `${filename}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleConfirmDelete = async () => {
        try {
            console.log('Deleting company with ID:', companyIdToDelete);
            await axios.delete(
                `${REACT_APP_SERVER_URL}/api/cnh/delete-cnh-data`,
                {
                    params: {
                        _id: companyIdToDelete,
                    },
                }
            );

            console.log('Company deleted successfully');
        } catch (error) {
            console.error('Error deleting company:', error);
        } finally {
            setCompanyIdToDelete(null);
            setShowConfirmationPopup(false);
            window.location.reload();
        }
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        setSelectedFiles(files);
    };

    const handleCopyClick = (text: string) => {
        navigator.clipboard.writeText(text);
        setCopiedText(`Texto copiado: ${text}`);

        // Limpar a mensagem após alguns segundos (opcional)
        setTimeout(() => {
            setCopiedText('');
        }, 3000);
    };

    const handleDeleteAll = () => {
        setShowDeletePopup(true); // Abre o popup
    };

    const handleCancelDeleteAll = () => {
        setShowConfirmationPopupAllDelete(false);
    };

    const handleFileUpload = (
        event: React.ChangeEvent<HTMLInputElement>,
        filename: string
    ) => {
        const file = event.target.files?.[0];

        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('filename', filename);

            // Enviar o arquivo via API
            fetch('/upload', {
                method: 'POST',
                body: formData,
            })
                .then(response => response.json())
                .then(data => {
                    console.log('Arquivo enviado com sucesso:', data);
                })
                .catch(error => {
                    console.error('Erro ao enviar arquivo:', error);
                });
        }
    };

    const handleConfirmDeleteSelectedFolder = async () => {
        try {
            const user = getUserFromLocalStorage();

            setShowWaitPopupDeleteAll(true);

            if (user && user.userId) {
                const userId = user.userId.replace(/^"(.*)"$/, '$1');
                console.log('Deleting selected folder for user ID:', userId);

                // Envia o userId como um parâmetro de query e o nome da pasta no corpo da requisição DELETE
                await axios({
                    method: 'delete',
                    url: `${REACT_APP_SERVER_URL}/api/cnh/delete-all-projects`,
                    data: {
                        folder: selectedDeleteFolder, // Envia o nome da pasta no corpo da requisição
                    },
                    params: {
                        author_id: userId, // Envia o userId como um parâmetro de query
                    },
                });

                console.log('Selected folder deleted successfully');
            }
        } catch (error) {
            console.error('Error deleting selected folder:', error);
        } finally {
            setShowWaitPopupDeleteAll(false);
            window.location.reload(); // Recarrega a página para refletir as mudanças
        }
    };

    return (
        <div className="min-h-screen bg-[#111827] py-10">
            <div>
                <Link
                    to="/"
                    className="text-white hover:text-[#39A2A7]/80 font-semibold text-sm font-montserrat"
                >
                    &#8592; Voltar
                </Link>
            </div>
            <div className="flex mt-8 space-x-4">
                <div className="bg-[#1f2937] rounded-lg p-6  w-1/3 h-full">
                    <div className="mb-2">
                        <p className="text-2xl text-white font-semibold cursor-default font-montserrat rounded">
                            Assistente de IA para <br />
                            <span className="bg-[#02989E] px-2 py-1 animate-pulse font-montserrat rounded text-black text-2xl">
                                CNH
                            </span>
                        </p>
                    </div>
                    <p className="mb-4 text-sm font-montserrat text-white">
                        Extraia e organize dados da Carteira Nacional de
                        Habilitação.
                    </p>
                    <div className="flex flex-col items-center mb-2">
                        <label
                            htmlFor="fileInput"
                            className="cursor-pointer w-full"
                        >
                            <div
                                className="bg-[#1f2937] border-dashed border border-white py-2 px-4 rounded-md text-white flex items-center justify-center font-montserrat"
                                id="inputDocument"
                            >
                                Selecionar Documentos
                            </div>
                        </label>
                        <input
                            type="file"
                            accept=".pdf, image/png, image/jpeg"
                            multiple
                            className="hidden"
                            id="fileInput"
                            onChange={handleFileChange}
                        />
                        {selectedFiles && selectedFiles.length > 0 && (
                            <p className="mt-2 text-base font-light font-montserrat text-yellow-300 italic">
                                {`${selectedFiles.length} ${
                                    selectedFiles.length === 1
                                        ? 'documento selecionado'
                                        : 'documentos selecionados'
                                }`}
                            </p>
                        )}
                    </div>
                    <button
                        onClick={() => handleSearchClick()}
                        id="search-button"
                        className={`mt-3 bg-[#111827] text-white px-6 py-2 rounded font-medium hover:bg-[#111827]/50 focus:outline-none focus:ring focus:border-blue-300 font-montserrat ${
                            loading ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                        disabled={loading}
                    >
                        {loading ? 'Aguarde...' : <RiRobot2Line size={24} />}
                    </button>
                    <button
                        type="button"
                        id="download"
                        className="mt-2 ml-3 bg-[#111827] text-white px-6 py-2 rounded font-medium hover:bg-[#111827]/50 focus:outline-none focus:ring focus:border-blue-300 font-montserrat
            "
                        onClick={handleDownloadExcel}
                    >
                        <Download />
                    </button>
                    <button
                        type="button"
                        id="delete-all"
                        className="mt-2 ml-3 bg-[#111827] text-white px-6 py-2 rounded font-medium hover:bg-[#111827]/50 focus:outline-none focus:ring focus:border-blue-300 font-montserrat"
                        onClick={() => handleDeleteAll()}
                    >
                        <MdOutlineDelete size={24} />
                    </button>
                </div>
                <div></div>
                <div>
                    <div
                        className="flex justify-center -mt-14 text-sm font-montserrat"
                        id="kpis"
                    >
                        <div>
                            <div className="mx-0 md:max-w-8xl lg:max-w-8xl xl:max-w-12xl ml-30 mt-10">
                                <div
                                    className="flex justify-center -mt-14 text-sm font-montserrat"
                                    id="kpis"
                                >
                                    <div className="mx-0 md:max-w-8xl lg:max-w-8xl xl:max-w-12xl ml-30">
                                        <div className="grid grid-cols-1 md:grid-cols-6 gap-4 ml-5">
                                            {/* Total de Notas */}
                                            <div className="col-span-1 bg-[#1f2937] border border-[#2c3848] text-white p-4 rounded-lg shadow-md">
                                                <p className="text-base text-muted-foreground font-montserrat">
                                                    Total de CNHs
                                                </p>
                                                <p className="text-xl font-semibold">
                                                    {totalNotas}
                                                </p>
                                            </div>

                                            {/* Notas Distintas */}
                                            <div className="col-span-1 bg-[#1f2937] border border-[#2c3848] text-white p-4 rounded-lg shadow-md">
                                                <p className="text-base text-muted-foreground font-montserrat">
                                                    CPFs Distintos
                                                </p>
                                                <p className="text-xl font-semibold">
                                                    {notasDistintas}
                                                </p>
                                            </div>

                                            <div className="col-span-1 bg-[#1f2937] border border-[#2c3848] text-white p-4 rounded-lg shadow-md">
                                                <p className="text-base font-montserrat">
                                                    Categorias Distintas
                                                </p>
                                                <p className="text-xl font-semibold">
                                                    {servicosDistintos}
                                                </p>
                                            </div>

                                            <button
                                                className="col-span-1 flex items-center justify-center border-dashed border-[#2c3848] border-2 text-[#ffffff] p-4 rounded-lg shadow-md hover:bg-[#1f2937] hover:text-white transition-all duration-300 disabled:bg-[#3b3f43] disabled:border-[#6c757d] disabled:text-[#a6a6a6] cursor-not-allowed"
                                                onClick={() => {
                                                    window.open(
                                                        '/kpis-invoices',
                                                        '_blank'
                                                    );
                                                }}
                                                disabled
                                            >
                                                <p className="text-base font-montserrat">
                                                    <FaPlus size={20} />
                                                </p>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center space-x-2 mt-4 mb-0">
                        <input
                            type="text"
                            id="search"
                            value={searchFilter}
                            onChange={e => setSearchFilter(e.target.value)}
                            placeholder="Pesquisar pelo Documento..."
                            className="w-[700px] ml-5 h-10 px-4 text-sm placeholder-gray-400 bg-[#1f2937] border border-[#ffffff] rounded-md focus:outline-none focus:border-[#39A2A7] text-white font-montserrat"
                        />
                        <div className="relative">
                            <select
                                value={selectedFolder}
                                onChange={e =>
                                    setSelectedFolder(e.target.value)
                                }
                                className="appearance-none bg-[#1f2937] border border-[#ffffff] text-white rounded-md h-10 pl-4 pr-8 w-[190px] ml-2 font-montserrat"
                            >
                                <option value="">Todas as pastas</option>
                                {folders.map(folder => (
                                    <option key={folder} value={folder}>
                                        {folder}
                                    </option>
                                ))}
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg
                                    className="fill-current h-4 w-4"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                >
                                    <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
                                </svg>
                            </div>
                        </div>
                    </div>

                    <div
                        className="container mx-auto p-4 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-12"
                        id="companies"
                    >
                        {currentFilteredCompanies.length === 0 ? (
                            <div className="flex items-center justify-center mt-10">
                                <p className="text-gray-500 text-center text-lg font-montserrat">
                                    Nenhuma CNH adicionada.
                                </p>
                            </div>
                        ) : (
                            currentFilteredCompanies
                                .filter(
                                    company =>
                                        selectedFolder === '' ||
                                        company.folder === selectedFolder
                                )
                                .map(company => (
                                    <div
                                        key={company._id}
                                        className="bg-[#1f2937] text-white rounded-lg p-6 cursor-pointer mr-0 transition-transform hover:scale-105 w-96 md:w-72 h-auto -mt-0 relative group"
                                    >
                                        <div className="flex justify-between items-center">
                                            <X
                                                size={24}
                                                className="cursor-pointer text-red-500 hover:text-red-700 -mt-4"
                                                onClick={() =>
                                                    handleDeleteCompany(
                                                        company._id
                                                    )
                                                }
                                            />

                                            <div className="bg-[#111827] text-white text-sm font-semibold px-3 py-1 rounded-full flex items-center space-x-2 -mt-2 mb-2 font-montserrat">
                                                <FaRegFolderOpen
                                                    size={20}
                                                    className="text-[#02989E]"
                                                />
                                                <span>
                                                    {company.folder ||
                                                        'Sem Pasta'}
                                                </span>
                                            </div>
                                        </div>

                                        <div className="relative mt-2">
                                            <div className="flex items-center">
                                                <div className="w-full">
                                                    <p
                                                        className="text-sm font-medium font-montserrat mb-0 mt-1 overflow-hidden overflow-ellipsis max-w-full whitespace-nowrap"
                                                        onClick={() =>
                                                            handleCopyClick(
                                                                company.nomeSobrenome ||
                                                                    'N/A'
                                                            )
                                                        }
                                                    >
                                                        {company.nomeSobrenome ||
                                                            'N/A'}
                                                    </p>
                                                    <p
                                                        className="text-yellow-400 text-xs font-montserrat mb-2"
                                                        onClick={() =>
                                                            handleCopyClick(
                                                                company.age
                                                                    ? `${company.age} anos`
                                                                    : 'N/A'
                                                            )
                                                        }
                                                    >
                                                        {company.age
                                                            ? `${company.age} anos`
                                                            : 'N/A'}
                                                    </p>

                                                    <p
                                                        className="text-gray-400 text-xs font-montserrat mb-1 overflow-hidden overflow-ellipsis max-w-full whitespace-nowrap"
                                                        onClick={() =>
                                                            handleCopyClick(
                                                                company.dataNascimento ||
                                                                    'N/A'
                                                            )
                                                        }
                                                    >
                                                        Data de Nascimento:{' '}
                                                        {company.dataNascimento ||
                                                            'N/A'}
                                                    </p>

                                                    <p
                                                        className="text-gray-400 text-xs font-montserrat mb-1"
                                                        onClick={() =>
                                                            handleCopyClick(
                                                                company.categoria ||
                                                                    'N/A'
                                                            )
                                                        }
                                                    >
                                                        Categoria:{' '}
                                                        {company.categoria ||
                                                            'N/A'}
                                                    </p>

                                                    <p
                                                        className="text-gray-400 text-xs font-montserrat mb-1 overflow-hidden overflow-ellipsis max-w-full whitespace-nowrap"
                                                        onClick={() =>
                                                            handleCopyClick(
                                                                company.validadeCNH ||
                                                                    'N/A'
                                                            )
                                                        }
                                                    >
                                                        Validade CNH:{' '}
                                                        {company.validadeCNH ||
                                                            'N/A'}
                                                    </p>

                                                    <p
                                                        className="text-gray-400 text-xs font-bold font-montserrat mt-2"
                                                        onClick={() =>
                                                            handleCopyClick(
                                                                company.isCNHValid
                                                            )
                                                        }
                                                    >
                                                        {company.isCNHValid ? (
                                                            <span className="bg-green-500 text-black px-1 py-0.5 rounded">
                                                                Dentro da
                                                                Validade
                                                            </span>
                                                        ) : (
                                                            <span className="bg-red-500 text-black px-1 py-0.5 rounded">
                                                                Fora da Validade
                                                            </span>
                                                        )}
                                                    </p>

                                                    <div className="border border-solid border-blue-400 p-2 mb-2 mt-3 rounded">
                                                        <p
                                                            className="text-xs font-montserrat text-white font-medium"
                                                            onClick={() =>
                                                                handleCopyClick(
                                                                    `CPF: ${company.cpf}`
                                                                )
                                                            }
                                                        >
                                                            CPF:{' '}
                                                            <strong>
                                                                {company.cpf}
                                                            </strong>
                                                        </p>
                                                    </div>

                                                    {/* Upload de Documento PDF */}
                                                    <div className="mt-4 hidden group-hover:block">
                                                        <label
                                                            className={`text-xs text-white font-montserrat font-semibold justify-center 
                bg-gray-700 hover:bg-[#111827] py-2 px-2 rounded-lg cursor-pointer 
                flex items-center ${
                    !company.croppedImageBuffer
                        ? 'opacity-50 cursor-not-allowed'
                        : ''
                }`}
                                                            onClick={() =>
                                                                company.croppedImageBuffer &&
                                                                handleDownloadImage(
                                                                    company.croppedImageBuffer,
                                                                    company.filename
                                                                )
                                                            }
                                                        >
                                                            <FaSignature className="mr-1" />{' '}
                                                            {company.croppedImageBuffer
                                                                ? 'Assinar Documento'
                                                                : 'Assinatura não disponível'}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                        )}
                        {copiedText && (
                            <div className="fixed bottom-4 right-4 bg-[#03689E] text-white font-semibold px-4 py-2 rounded">
                                {copiedText}
                            </div>
                        )}
                    </div>
                    <div className="flex justify-center mt-4 ml-62 text-gray-100 text-sm font-montserrat">
                        <button
                            onClick={handlePrevPage}
                            disabled={currentPage === 1}
                        >
                            Anterior
                        </button>
                        <span className="mx-2 text-gray-100 text-xs font-montserrat">
                            {currentPage}
                        </span>
                        <button
                            onClick={handleNextPage}
                            disabled={currentPage === totalPages}
                        >
                            Próxima
                        </button>
                    </div>
                </div>
            </div>
            <>
                {analysisCompleted && (
                    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-75">
                        <div className="bg-white rounded-lg shadow-md overflow-hidden w-96">
                            <div className="px-6 py-4 bg-[#02989E] text-white flex justify-between items-center">
                                <div className="flex items-center">
                                    <FaCheckCircle size={24} className="mr-2" />
                                    <h2 className="text-2xl font-semibold font-montserrat">
                                        Tarefa Concluída
                                    </h2>
                                </div>
                            </div>
                            <div className="px-6 py-4">
                                <p className="text-gray-800 text-2xl font-bold mb-2 font-montserrat">
                                    Relatório
                                </p>
                                <div className="flex items-center mb-1">
                                    <p className="text-gray-800 mr-2 font-montserrat text-lg">
                                        Total de Páginas:
                                    </p>
                                    <p className="text-gray-800 font-semibold font-montserrat text-xl">
                                        {numberOfPages}
                                    </p>
                                </div>
                                <div className="flex items-center mb-1">
                                    <p className="text-gray-800 mr-2 font-montserrat text-lg">
                                        Total de PDFs:
                                    </p>
                                    <p className="text-gray-800 font-semibold font-montserrat text-xl">
                                        {totalFiles}
                                    </p>
                                </div>
                                <div className="flex items-center mb-1">
                                    <p className="text-gray-800 mr-2 font-montserrat text-lg">
                                        Análise com Sucesso:
                                    </p>
                                    <p className="text-gray-800 font-semibold font-montserrat text-xl">
                                        {successfulFiles}
                                    </p>
                                </div>
                                <div className="flex items-center mb-1">
                                    <p className="text-gray-800 mr-2 font-montserrat text-lg">
                                        Análise com Falha:
                                    </p>
                                    {errorFilesNames.length > 0 ? (
                                        <p className="text-gray-800 font-semibold font-montserrat text-lg">
                                            {errorFilesNames.join(', ')}
                                        </p>
                                    ) : (
                                        <p className="text-gray-800 font-semibold font-montserrat text-lg">
                                            0
                                        </p>
                                    )}
                                </div>
                                <div className="flex justify-end mt-6">
                                    <button
                                        className="text-white bg-[#02989E] px-6 py-2 rounded-md hover:bg-[#02807a] font-semibold focus:outline-none font-montserrat"
                                        onClick={handlePopupOkClick}
                                    >
                                        Ok
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
            {showConfirmationPopup && (
                <ConfirmationPopup
                    message="Tem certeza de que deseja excluir esta CNH?"
                    onCancel={handleCancelDelete}
                    onConfirm={handleConfirmDelete}
                />
            )}
            {showWaitPopup && (
                <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-75">
                    <div className="bg-white rounded-lg shadow-md p-6">
                        <div className="flex items-center justify-center">
                            <RiRobot2Line className="w-16 h-16 text-[#02989E] animate-pulse" />
                        </div>
                        <p className="text-xl font-semibold text-center mt-4 font-montserrat">
                            Aguarde... estou analisando os documentos enviados.
                        </p>
                    </div>
                </div>
            )}
            {showInputPopup && (
                <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75">
                    <div className="bg-white rounded shadow-lg p-6 w-96">
                        <div className="mb-4">
                            <label
                                htmlFor="existingFolders"
                                className="block text-gray-700 text-lg font-montserrat mb-2 font-semibold"
                            >
                                Pastas Existentes:
                            </label>
                            <select
                                id="existingFolders"
                                className="mb-4 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 bg-white leading-tight focus:outline-none focus:shadow-outline font-montserrat"
                                onChange={e => {
                                    setInputValue(e.target.value);
                                    setIsFolderSelected(e.target.value !== '');
                                }}
                            >
                                <option value="">
                                    Selecione uma pasta existente
                                </option>
                                {folders.map(folder => (
                                    <option key={folder} value={folder}>
                                        {folder}
                                    </option>
                                ))}
                            </select>
                            <label
                                htmlFor="inputValue"
                                className="block text-gray-700 text-lg font-montserrat mb-2 font-semibold"
                            >
                                {isFolderSelected
                                    ? 'A pasta selecionada foi:'
                                    : 'Insira uma nova pasta:'}
                            </label>
                            <input
                                type="text"
                                value={inputValue}
                                onChange={e => setInputValue(e.target.value)}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline font-montserrat"
                                id="inputValue"
                            />
                        </div>
                        <div className="flex items-center justify-end">
                            <button
                                className="mr-2 px-4 py-2 bg-gray-500 hover:bg-gray-600 text-white rounded font-montserrat transition-colors duration-150"
                                onClick={() => setShowInputPopup(false)}
                            >
                                Cancelar
                            </button>
                            <button
                                disabled={!inputValue}
                                className={`px-4 py-2 text-white rounded font-montserrat transition-colors duration-150 ${
                                    inputValue
                                        ? 'bg-[#02989E] hover:bg-[#02989E]/90'
                                        : 'bg-gray-200 cursor-not-allowed'
                                }`}
                                onClick={() => inputValue && handleConfirm()}
                            >
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {showDownloadPopup && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
                    <div className="bg-white rounded-lg shadow-xl p-6 w-full max-w-md">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-xl font-semibold text-gray-900 font-montserrat">
                                Download de Notas Fiscais
                            </h2>
                            <button
                                onClick={() => setShowDownloadPopup(false)}
                                className="text-gray-600 hover:text-gray-900 rounded-full"
                            >
                                <svg
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div className="mb-4">
                            <label
                                htmlFor="downloadFolder"
                                className="block text-gray-700 font-medium mb-2 font-montserrat"
                            >
                                Escolha a pasta:
                            </label>
                            <select
                                id="downloadFolder"
                                value={selectedDownloadFolder}
                                onChange={e =>
                                    setSelectedDownloadFolder(e.target.value)
                                }
                                className="font-montserrat block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                            >
                                <option value="">Todas as Pastas</option>
                                {folders.map(folder => (
                                    <option key={folder} value={folder}>
                                        {folder}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-4">
                            <label
                                htmlFor="datePicker"
                                className="block text-gray-700 font-medium mb-2 font-montserrat"
                            >
                                Selecione a Data:
                            </label>
                            <input
                                type="date"
                                id="datePicker"
                                value={selectedDate}
                                onChange={e => setSelectedDate(e.target.value)}
                                className="font-montserrat block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-[#02989E]/90 focus:border-[#02989E]"
                            />
                        </div>
                        <div className="flex items-center justify-end pt-4 border-t border-gray-200">
                            <button
                                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-lg font-medium rounded-md text-white bg-[#02989E] hover:bg-[#02989E]/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#02989E]/90"
                                onClick={handleDownloadSelectedFolder}
                            >
                                Download
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {showDeletePopup && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
                    <div className="bg-white rounded-lg shadow-xl p-6 w-full max-w-md">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-xl font-semibold text-gray-900 font-montserrat">
                                Deletar CNHs
                            </h2>
                            <button
                                onClick={() => setShowDeletePopup(false)}
                                className="text-gray-600 hover:text-gray-900 rounded-full p-2"
                            >
                                {/* Aqui adicionamos um X para servir como ícone de fechar */}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div className="mb-4">
                            <label
                                htmlFor="deleteFolder"
                                className="block text-gray-700 font-medium mb-2 font-montserrat"
                            >
                                Escolha a pasta:
                            </label>
                            <select
                                id="deleteFolder"
                                value={selectedDeleteFolder}
                                onChange={e =>
                                    setSelectedDeleteFolder(e.target.value)
                                }
                                className="font-montserrat block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                            >
                                <option value="">Todas as Pastas</option>
                                {folders.map(folder => (
                                    <option key={folder} value={folder}>
                                        {folder}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="flex items-center justify-end pt-4 border-t border-gray-200">
                            <button
                                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-lg font-medium rounded-md text-white bg-[#02989E] hover:bg-[#02989E]/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#02989E]/90"
                                onClick={handleConfirmDeleteSelectedFolder}
                            >
                                Deletar
                            </button>
                        </div>
                        {deleteStatusMessage && (
                            <p className="text-center text-red-500">
                                {deleteStatusMessage}
                            </p>
                        )}
                        {showWaitPopupDeleteAll && (
                            <p className="text-base font-semibold text-center mt-4 font-montserrat">
                                Aguarde... estou deletando as CNHs selecionadas.
                            </p>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Cnh;
